.notification_container .contentText{
 font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 21px;
color: #44454D;

}

.notification_container .time{
 font-family: 'Poppins';
 font-style: normal;
 font-weight: 400;
 font-size: 12px;
 line-height: 18px;
 color: #979797;}

 .BoldText{
  font-weight: 600  !important;
 }


 /* notification dropdown  */
 #notification_badge > span{
  top:2px;
  right: 4px;
}


#notification-menu {
  z-index: 1400;
  /* max-width: 925px; */
}


#notification-menu li.MuiMenuItem-root{
  white-space: break-spaces;
  max-width: 500px;
  align-items: flex-start;
  min-height: 25px !important;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  justify-content: flex-start;
}



#account-menu {
  z-index: 1400;
  /* max-width: 925px; */
}


#account-menu li.MuiMenuItem-root{
  white-space: break-spaces;
  max-width: 500px;
  align-items: flex-start;
  width: auto;
  justify-content: normal;
  display: flex;
  padding: 0 10px;
}