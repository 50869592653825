@media only screen and (max-width: 1366px) {}

@media only screen and (max-width:1280px) {}

@media only screen and (max-width:1080px) {
 .talenter_profile_middle {
  width: 100%;
 }

 .outer_bg_section {
  padding: 3px 31px;
 }

 .personal_data_title h2 {
  font-size: 20px;
 }

 .divider {
  margin: 1px 0 0 0;
 }
}

@media only screen and (max-width:1280px) {
 .top_profile_name {
  width: 100%;
 }
}

/* Admin profile page */



@media screen and (max-width: 568px) {
 .sm_displayBlock {
  display: block;
 }

 .sm_displayNone {
  display: none;
 }

 /* section */
 .top_profile_section {
  padding-bottom: 0;
 }

 .middle_bar_section {
  padding: 13px 0;
 }

 /* user name */
 .profile_name_left h3 {
  font-size: 22px;
 }

 .unfave_icon_fave_icons {
  margin-top: 12px;
 }


 /* photo gallery  */
 .act__user_profile_top h3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
 }

 .act__user_profile_top h3 span {
  display: flex;
 }

 .profile_name__accd h3 {
  font-size: 20px;
 }

 .profile_name__accright {
  float: none;
 }

 .profile_name__accright .MuiButton-root {
  right: 42px;
  top: -58px;
  line-height: 21px;
  position: absolute;
 }

 .Moblie_edit {
  right: 42px;
  top: -57px;
  line-height: 21px;
  position: absolute;
 }

 .images_uploader_left {
  padding-left: 11px;
  padding-top: 22px;
 }
 .footer_left_item h2{padding: 7px 0 0px 0;}

 .footer_social_media ul li{
  padding: 6px 11px 0 0px;
 }
 .footer_left_item ul li{
  padding: 0;
 }
 .footer_left_item p{
  margin: 10px 0 0 0;
 }
 .footer_social_media h2{
  padding-top: 10px;
  color: #000;
  font-weight: 500;
 }
}

@media screen and (max-width: 1000px) {
 .footer_leftpadding {
  padding: 0;
 }
}
