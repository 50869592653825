@import "~react-image-gallery/styles/css/image-gallery.css";
body {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    background: #e5e5e5;
}

body {
    font-family: 'Poppins', sans-serif !important;
}

/*Footer css start*/
.footer_left_item {
    display: inline-block;
    width: 100%;
}

.input_textColor .MuiInput-root   [type='text'] {
    color: #9b26b6;
    font-weight: 700;
    cursor: pointer !important;
}

.common_footer_section {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    padding: 64px 0;
    background: #fff;
}

.footer_left_item p {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    color: #000;
    font-weight: 300;
    margin: 18px 0 0 0;
}

.footer_left_item ul {
    display: inline-block;
    width: 100%;
}

.footer_left_item h2 {
    display: inline-block;
    width: 100%;
    font-size: 15px;
    color: #000;
    font-weight: 500;
    padding: 0 0 14px 0;
}

.footer_left_item ul li {
    display: inline-block;
    width: 100%;
    padding: 4px 0;
}

.footer_left_item ul li a {
    display: inline-block;
    width: 100%;
    font-size: 15px;
    color: #000;
    font-weight: 300;
}

.footer_left_item ul li a:hover {
    color: #8D16A9;
    font-weight: 500;
}

.footer_social_media ul li {
    display: inline-block;
    padding: 14px 11px 0 0px;
}

.footer_social_media ul li a {
    display: inline-block;
    width: 100%;
    font-size: 15px;
    color: #000;
    font-weight: 300;
}

.footer_social_media ul li a:hover {
    color: #8D16A9;
    font-weight: 500;
}

a {
    transition: all 500ms ease-in-out;
}

.relative.user_signuo_button button {
    background: #9B26B6;
    color: #fff;
    padding: 0px 9px;
    border-radius: 32px;
    min-height: 42px;
}

.before_login_links li a {
    font-size: 16px;
    color: #101820;
    font-weight: 500;
}

.before_login_links li.sigin_color a {
    font-size: 14px;
    color: #9B26B6;
    font-weight: 500;
}

.before_login_links li.relative.user_signuo_button .user_name {
    font-size: 12px;
    color: #fff;
    font-weight: 500;
    padding: 0 0 0 2px;
}

.object-cover {
    border: 2px solid #fff;
}

.before_login_links li a:hover {
    color: #9B26B6;
}

.before_login_links h2 {
    display: inline-block;
    width: 100%;
    font-size: 15px;
    color: #000;
    font-weight: 500;
    padding: 0 0 14px 0;
}

.new_header_section ul li a {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    padding: 0 4px;
}

.new_header_section ul li.haeder_signin a {
    font-size: 14px;
    color: #9B26B6;
    font-weight: 500;
}

.overflow-y-auto {
    background: #F4F4F4;
}

.dshbord_wellcome_text h1 {
    display: inline-block;
    width: 100%;
    font-size: 36px;
    font-weight: 600;
    color: #101820;
    margin: 0px 0 15px 0;
}

.dshbord_wellcome_text label {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    color: #9B26B6;
    margin: 0px;
}

.dshbord_wellcome_text p {
    display: inline-block;
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    color: #44454D;
    margin: 23px 0 23px 0;
    line-height: 24px;
}

.dshbord_wellcome_text h4 {
    display: inline-block;
    width: 100%;
    font-size: 24px;
    font-weight: 600;
    color: #101820;
    margin: 15px 0 34px 0;
}

.dshbord_wellcome_text a button {
    background: #9B26B6;
    height: 48px;
    padding: 0 93px;
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: inherit;
}

.dshbord_wellcome_text a button:hover {
    background: #363636;
}

.dshbord_wellcome_text {
    display: inline-block;
    width: 100%;
    margin: 8% 0 0 0;
}

.talenter_profile_middle_w-full {
    width: 100% !important;
}

.talenter_profile_middle {
    margin: 0 auto;
    width: 1080px;
    margin-bottom: 48px;
}


.outer_bg_section {
    display: inline-block;
    width: 100%;
    padding: 14px 63px;
    padding-bottom: 50px;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
}

.step_buttons button {
    width: 50px;
    height: 50px;
    background: #979797;
    position: relative;
    z-index: 2;
}

.step_buttons .StepButton-d0-0-2-6.active {
    background: #9B26B6;
}

.step_buttons .step1 span {
    background: url('../img/personal-data.png') no-repeat;
    width: 30px;
    height: 30px;
    font-size: 0px;
}

.step_buttons .step2 span {
    background: url('../img/physical-description.png') no-repeat;
    width: 30px;
    height: 30px;
    font-size: 0px;
    background-position: 5px 2px;
}

.step_buttons .step3 span {
    background: url('../img/preferences.png') no-repeat;
    width: 30px;
    height: 30px;
    font-size: 0px;
    background-position: 0px 2px;
    background-size: 100%;
}

.step_buttons .step4 span {
    background: url('../img/languages-travel.png') no-repeat;
    width: 30px;
    height: 30px;
    font-size: 0px;
    background-position: 0px 2px;
    background-size: 100%;
}

.step_buttons .step5 span {
    background: url('../img/skills-education.png') no-repeat;
    width: 34px;
    height: 30px;
    font-size: 0px;
    background-position: 0px 2px;
}

.step_buttons .step6 span {
    background: url('../img/upload-Images.png') no-repeat;
    width: 34px;
    height: 39px;
    font-size: 0px;
    background-position: -1px 1px;
}

.step_buttons #RFS-LabelContainer span {
    font-family: Poppins;
    font-size: 9px;
    font-weight: 600;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: center;
    color: #979797;
    margin: 12px 0 0 0;
}

.talenter_profile_middle #RFS-Connector {
    position: relative;
    border-radius: 24px;
    top: 9px;
    background: #E5E5E5;
    height: 4px;
    border: none;
}

.personal_data_title {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 13px 0 20px 0;
}

.personal_data_title label {
    display: inline-block;
    width: 100%;
    color: #9B26B6;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
}

.personal_data_title h2 {
    display: inline-block;
    width: 100%;
    color: #44454D;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
}

.divider {
    width: 67px;
    height: 3px;
    border-bottom: 4px solid #9b26b6;
    display: inline-block;
    vertical-align: top;
    margin: 6px 0 0 0;
}

.label_text {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    color: #44454D;
    text-align: left;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    padding: 0 0 7px 0;
}

.personal_data_section .border_bottom .border-gray-300 {
    border: none;
    border-bottom: 1px solid #E5E5E5;
    border-radius: 0px;
    padding-left: 0px;
}

.submit_buttons_bottom {
    display: inline-block;
    width: 100%;
    padding: 0px 0 0 0;
}

.submit_buttons_right {
    float: right;
    width: 100%;
    margin: 35px 0 0px 0;
}

.radio-img {
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    padding: 0 18px 0 0px;
}

.slect_gender {
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #979797;
    vertical-align: top;
    padding: 11px 19px 0 0;
}

.names_list {
    font-size: 8px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: center;
    color: #979797;
}

.tooltip_msg {
    display: inline-block;
    padding: 0 5px 0 0px;
    float: right;
    vertical-align: middle;
}

.tooltip_msg img {
    display: inline-block;
    vertical-align: middle;
}

.personal_data_section em {
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    font-style: inherit;
    text-align: left;
    color: #979797;
}

.submit_buttons_bottom [type='checkbox'] {
    width: 22px;
    height: 22px;
    cursor: pointer;
}

.form_center_img {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 18px 0 0 0;
}

.form_center_img img {
    display: inline-block;
    max-width: 100%;
    text-align: center;
}

.left_slider {
    float: left;
    width: 69%;
    margin: 9px 0 0 0;
}

.right_button {
    float: right;
    width: 26%;
}

.form_right .label_text {
    margin: 4px 0 6px 0;
}

.button_in {
    float: left;
    background: #9B26B6;
    border-radius: 5px 0px 0px 5px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    width: 45px;
    color: #fff;
    height: 30px;
}

.button_cm {
    float: left;
    background: #F1F1F1;
    border-radius: 0px 5px 5px 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    width: 45px;
    color: #979797;
    height: 30px;
}


.physical_button_active {
    float: left;
    background: #9B26B6;
    border-radius: 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    width: 45px;
    color: #fff;
    height: 30px;
}

.physical_button_inactive {
    float: left;
    background: #F1F1F1;
    border-radius: 0px 5px 5px 0px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    width: 45px;
    color: #979797;
    height: 30px;
}

.terms_of_use {
    display: inline-block;
    width: 100%;
    background: #e5e5e5;
    padding: 0;
    margin: 0;
}

.cms_moduls_section {
    background: #f3f2f3;
    ;

}

.terms_of_use h2 {
    display: inline-block;
    width: 100%;
    font-size: 24px;
    font-weight: bold;
    color: #44454d;
    min-height: 80px;
    vertical-align: middle;
    padding: 22px 0;
}


.cms_sidebar ul li .doc_icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    background: url('../img/icon_hover.png') no-repeat;
}

.cms_sidebar ul li .compy_icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    background: url('../img/icon.company.svg') no-repeat;
}

.cms_sidebar ul li:hover .compy_icon {
    background: url('../img/icon.company.png') no-repeat;
}

.cms_sidebar ul li.active a .compy_icon {
    background: url('../img/icon.company.png') no-repeat;
}

.cms_sidebar ul li .contact_icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    background: url('../img/icon.mail.png') no-repeat;
}

.cms_sidebar ul li:hover .contact_icon {
    background: url('../img/icon.mail.svg') no-repeat;
}

.cms_sidebar ul li.active a .contact_icon {
    background: url('../img/icon.mail.svg') no-repeat;
}

.cms_sidebar ul li a {
    display: inline-block;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    text-decoration: none;
    width: 100%;
    padding: 12px 13px;
}

.cms_sidebar ul li a:hover {
    background: #E5E5E5;
    color: #9B26B6;
}

.cms_sidebar ul li .pri_icon.doc_icon {
    background: url('../img/icon.policy.png') no-repeat;
}

.cms_sidebar ul li:hover .doc_icon {
    background: url('../img/icon.terms.png') no-repeat;
}


.cms_sidebar ul li:hover .pri_icon.doc_icon {
    background: url('../img/icon.policy_hover.png') no-repeat;
}

.cms_section_new {
    display: inline-block;
    width: 100%;
    margin: 50px 0 0 0;
}

.cms_sidebar ul li.active a {
    background: #E5E5E5;
    color: #9B26B6;
}

.cms_sidebar ul li.active a .doc_icon {
    background: url('../img/icon.terms.png') no-repeat;
}



.cms_sidebar ul li a .doc_icon.about_us {
    background: url('../img/about_us.png') 0px -135px no-repeat;
}

.cms_sidebar ul li a .contactus.doc_icon {
    background: url('../img/about_us.png') 0px -179px no-repeat;
}

.cms_sidebar ul li.active .doc_icon.about_us {
    background: url('../img/about_us.png') -53px -135px no-repeat;
}

.cms_sidebar ul li:hover .contactus.doc_icon {
    background: url('../img/about_us.png') -53px -179px no-repeat;
}

.cms-para {
    display: inline-block;
    width: 100%;
    background: #fff;
    margin: 0px 0;
    padding: 37px;
    border-radius: 10px;
}

.paragraph {
    display: inline-block;
    width: 100%;
}

.cms-para h2 {
    display: inline-block;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    color: #44454d;
    margin-top: 15px;
}

.cms-para p {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    margin-top: 18px;
    color: #44454d;
}

.cms-para .paragraph small {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    margin: 16px 0 37px 0;
    color: #44454d;
}


.talenter_profile_middle .StepButton-d2-0-2-12.active {
    background-color: #9B26B6;
}

.talenter_profile_middle .StepButton-d0-0-2-6.completed {
    background-color: #9B26B6;
}

.talenter_profile_middle .StepButton-d4-0-2-20.active {
    background-color: #9B26B6;
}

.talenter_profile_middle .StepButton-d6-0-2-26.active {
    background-color: #9B26B6;
}

.talenter_profile_middle .StepButton-d2-0-2-12.completed:hover {
    background-color: #9B26B6;
}

.talenter_profile_middle .StepButton-d2-0-2-12.completed {
    background-color: #9B26B6;
}

.talenter_profile_middle .StepButton-d4-0-2-20.completed {
    background-color: #9B26B6;
}

.talenter_profile_middle .StepButton-d6-0-2-26.completed {
    background-color: #9B26B6;
}

.talenter_profile_middle .StepButton-d8-0-2-32.active {
    background-color: #9B26B6;
}

.border_bottom select {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
}

.border_bottom [type='text'],
.border_bottom textarea {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    border-width: medium;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    box-shadow: none;

}

.MuiInput-input.MuiInputBase-input.Mui-disabled {
    -webkit-text-fill-color: inherit;
}

.color_fad {
    font-size: 12px;
    color: #c9c9c9;
    display: inline-block;
    font-weight: 400;
}

.color_opt {
    font-size: 12px;
    color: #44454D;
    display: inline-block;
    font-weight: 400;
}


.edu_title {
    font-size: 18px;
    font-weight: 600;
    color: #9B26B6;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
}

.label_text.top_more_space {
    margin: 0 0 26px 0;
}

.edu_title.move_bottom {
    display: inline-block;
    width: 100%;
    margin: 30px 0 0 0;
}

.edu_title.move_top_bot {
    margin: 28px 0 26px 0;
}

.phone_number {
    float: left;
    width: 62%;
    margin: 7px 0 0 0;
}
.phone_number_recruiter {
    float: left;    margin: 7px 0 0 0;
}

.verfiy_buttons {
    float: left;
    width: 36%;
}

.verfiy_icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('../img/whatsapp.png') no-repeat;
    vertical-align: middle;
    margin: 1px 8px 0 0;
}

.value_broder input,
.value_broder select,
.value_broder .MuiInputBase-root:before {
    border-bottom: 1px solid #9b26b6 !important;
}

/* .verfiy_buttons button {
    border: 1px solid #9B26B6;
    animation-timing-function: ease-out;
    animation-duration: 300ms;
    color: #9B26B6;
    align-items: flex-start;
    padding: 8px 15px 8px 12px;
    background: #fff;
    height: 36px;
    line-height: 19px;
    text-transform: inherit;
    width: 113px;
    border-radius: 4px;
    float: right;
    vertical-align: middle;
} */

.formLabelText .MuiTypography-root {
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0;
    font-family: 'Poppins';
    text-align: left;
    padding: 0 0 0 11px;
    color: #44454D;
}

.outer_bg_section .verfiy_buttons button {
    border: 1px solid #9B26B6;
    animation-timing-function: ease-out;
    animation-duration: 300ms;
    color: #9B26B6;
    align-items: flex-start;
    padding: 8px 15px 8px 12px;
    background: #fff;
    height: 36px;
    line-height: 19px;
    text-transform: inherit;
    width: 113px;
    border-radius: 4px;
    float: right;
    vertical-align: middle;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
}

.verfiy_buttons button:hover {
    background: #fbfbfb;
}

.what_up_date {
    float: left;
    width: 100%;
    margin: 12px 0 0 0;
}

.what_up_date_rec {
    float: left;
    width: 100%;
    margin: 12px 0 0 0;
}

.what_up_date span {
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    padding: 0 0 0 12px;
}

.what_up_date_rec span {
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    padding: 0 0 0 12px;
}

.border_bottom [type='file'] {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
    width: 100%;
    height: 32px;
    margin: 10px 0 0 0;
}

/* .new_header_section{padding:22px 0;} */
.prod_year {
    float: left;
    width: 150px;
}

.add_more_button {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #9B26B6;
    background: url('../img/icon.plus.png') 0px 7px no-repeat;
    padding: 11px 0 12px 31px;
    border: none;
}

.home_page_banner {
    display: inline-block;
    width: 100%;
    position: relative;
}

.left_home_banner_content h1 {
    color: #101820;
    font-size: 48px;
    font-weight: 600;
    line-height: 72px;
    letter-spacing: 0em;
    text-align: left;
}

.left_home_banner_content p {
    color: #666C72;
    font-size: 24px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    margin: 7px 0 60px 0;
}

.left_home_banner_img {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 8% 0 0 0;
}

.left_home_banner_img img {
    display: inline-block;
    max-width: 100%;
    text-align: center;
}

.find_button button {
    background: #9B26B6;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 30px;
    height: 46px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
}

.work_button button {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 30px;
    height: 46px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
}

.our_clients h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #979797;
    display: inline-block;
    width: 100%;
    margin: 0 0 13px 0;
}

.our_clients ul {
    display: inline-block;
    width: 100%;
}

.our_clients ul li {
    display: inline-block;
    width: 20%;
}

.left_home_banner_content {
    display: inline-block;
    width: 100%;
    padding: 17% 0 0 0;
    position: relative;
    z-index: 1;
}

.unique_roles_section {
    background: linear-gradient(149.98deg, #44454D 17.8%, #9B9EA0 73.02%);
    display: inline-block;
    width: 100%;
    min-height: 500px;
    position: relative;
    overflow: hidden;
    vertical-align: top;
}

/* 
.relative.user_signuo_button .absolute {position:fixed; right:0px; width:350px;    height: 100vh;
overflow-y: auto;-webkit-overflow-scrolling: touch;    outline: 0;}

.relative.user_signuo_button .absolute {
right: -350px;
-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
-moz-transition: opacity 0.3s linear, right 0.3s ease-out;
-o-transition: opacity 0.3s linear, right 0.3s ease-out;
transition: opacity 0.3s linear, right 0.3s ease-out;
}

.relative.user_signuo_button .enter.enter-active .absolute{
right: 0;
} */
.unique_roles_section:before {
    position: absolute;
    content: "";
    background: #fff;
    width: 39%;
    height: 100%;
    top: 0px;
    right: -158px;
    -webkit-transform: skewX(22deg);
    -ms-transform: skewX(22deg);
    transform: skewX(22deg);
    z-index: 1;
}

.talent_left_info h3 {
    display: inline-block;
    width: 100%;
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
}

.talent_left_info p {
    display: inline-block;
    width: 100%;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
}

.talent_left_active_users {
    display: inline-block;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    min-height: 540px;
    position: relative;
    z-index: 1;
    margin: 11% 0;
    padding: 35px 47px;
}

.talent_left_info {
    display: inline-block;
    width: 83%;
    position: relative;
    z-index: 2;
    padding: 18% 0 0 0;
}

.talent_left_active_users h4 {
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #101820;
}

.talent_left_active_users p {
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #9B26B6;
    margin: 5px 0 13px 0;
}

.users_item {
    display: inline-block;
    width: 100%;
    text-align: center;
}

.users_item img {
    display: inline-block;
    max-width: 100%;
    text-align: center;
}

.header_icon {
    position: absolute;
    top: 14px;
    right: 27px;
}

.img_list {
    display: inline-block;
    width: 100%;
    position: relative;
}

.users_item label {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #101820;
    text-align: center;
    margin: 7px 0 0 0;
}

.unique_roles_section.show_your_talent:before {
    position: absolute;
    content: "";
    background: #fff;
    width: 39%;
    height: 100%;
    top: 0px;
    left: -158px;
    -webkit-transform: skewX(22deg);
    -ms-transform: skewX(22deg);
    transform: skewX(22deg);
    z-index: 1;
}

.unique_roles_section.show_your_talent {
    background: linear-gradient(149.98deg, #8D16A9 17.8%, #BB29BB 73.02%);
}

.unique_roles_section.show_your_talent .talent_left_info {
    padding: 18% 0 0 8%;
}

.unique_roles_section.show_your_talent:after {
    position: absolute;
    right: 0px;
    bottom: 0px;
    content: '';
    width: 100%;
    height: 100%;
    background: url('../img/start_ratings.png') right bottom no-repeat
}

.unique_roles_section:after {
    position: absolute;
    left: 0px;
    bottom: 0px;
    content: '';
    width: 100%;
    height: 100%;
    background: url('../img/start_ratings.png') left bottom no-repeat;
}

.left_label {
    display: inline-block;
    width: 30%;
}

.right_text_feild {
    display: inline-block;
    width: 70%;
}

.left_label span {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #fff;
}

.submit_before_login {
    float: right;
    width: 70%;
}

.right_text_feild .leading-5 {
    line-height: 28px;
}

.submit_before_login p {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.submit_before_login .submit_button {
    background: #FFFFFF;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 30px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #101820;
    height: 47px;
    padding: 0 31px;
    margin: 16px 0 0 0;
}

.submit_before_login .submit_button:hover {
    background: linear-gradient(149.98deg, #8D16A9 17.8%, #BB29BB 73.02%);
    color: #fff;
}

.top_tobottom {
    display: inline-block;
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 2;
    margin: 0 0 32px 0
}

.top_tobottom img {
    display: inline-block;
    text-align: center;
}

.our_clients {
    display: inline-block;
    width: 100%;
    margin: 26px 0 36px 0;
    position: relative;
    z-index: 1;
}

#header_fixed {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
}

.common_height {
    display: inline-block;
    width: 100%;
    height: 67px;
}


.border_bottom [type='text']::-webkit-input-placeholder {
    color: #9f9f9f;
}

.border_bottom [type='text']::-moz-placeholder {
    color: #9f9f9f;
}

.border_bottom [type='text']:-ms-input-placeholder {
    color: #9f9f9f;
}

.border_bottom [type='text']:-moz-placeholder {
    color: #9f9f9f;
}


.after_signup_section .left_profile {
    /* overflow: hidden; */
    width: 60px;
    height: 50px;
    /* border-radius: 50px; */
    /* float: left; */
    vertical-align: middle;
    margin-right: 10px;
}

.right_profile_info {
    float: left;
}

.after_signup_section svg {
    display: none;
}

.after_signup_section li a span {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
}

.right_profile_info h4 {
    display: inline-block;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #9B26B6;
    vertical-align: top;
}

.right_profile_info label {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    width: 100%;
    vertical-align: top;
}

.relative.user_signuo_button .after_signup_section {
    /* White */
    background: #FFFFFF;
    /* 5px Shadow */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
}

.after_signup_section li {
    margin: 0px 0 4px 0;
    padding: 0px;
}

.relative.user_signuo_button .after_signup_section {
    padding: 15px 11px;
}

.footer_leftpadding {
    padding: 0 0px 0 40px;
}

.home_page_banner:before {
    position: absolute;
    left: 0px;
    top: 0px;
    content: '';
    width: 645px;
    height: 100%;
    background: url('../img/home_page_banner.png') left bottom no-repeat;
}

.home_page_banner:after {
    position: absolute;
    left: 7%;
    top: -221px;
    content: '';
    width: 1060px;
    height: 100%;
    background: url('../img/home_banner_star.png') left bottom no-repeat;
}

.top_tobottom button {
    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}


.outer_bg_section_data .MuiFormControl-root,
.outer_bg_section_data .MuiInput-root {
    width: 100%;
}

.outer_bg_section_data .Mui-selected {
    color: #9B26B6 !important;
}

.gender_list .radio-img .MuiRadio-root {
    display: none;
}

.gender_list .radio-img span input:checked+.image {
    vertical-align: top;
    border-radius: 40px;
    cursor: pointer;
    border: 1px solid red;
}

/* .switch_design .MuiTypography-root {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0;
    text-align: left;
    padding: 0 0 0 11px;
    color: #44454D
} */

.switch_design .MuiTypography-root {
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0;
    font-family: 'Poppins';
    text-align: left;
    padding: 0 0 0 11px;
}

.uplode_file .MuiButton-root {
    width: 110px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #9B26B6;
    border: 1px solid #9B26B6;
    box-sizing: border-box;
    border-radius: 5px;
    background: #FFFFFF;
    height: 36px;
    text-transform: inherit;
    box-shadow: none;
    
}

.uplode_file .MuiButton-root:hover {
    background: #fff;
}

.to_add_style {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
}

.submit_buttons_bottom .cancel_button {
    height: 40px;
    width: 118px;
    border-radius: 5px;
    box-shadow: 0px 2px 5px 0px #0000000D;
    border: 1px solid #E5E5E5;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-transform: inherit;
    letter-spacing: 0em;
    text-align: center;
    padding: 0px;
    color: #44454D;
}

.submit_buttons_bottom .submit_button {
    height: 40px;
    width: 155px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: inherit;
    padding: 0 0 0 0px;
    background: #9B26B6;
    float: right;
    color: #fff;
}

.submit_buttons_bottom .submit_button:hover {
    background: #fff;
    color: #9B26B6;
}

.uplode_icon {
    display: inline-block;
    width: 14px;
    height: 18px;
    background: url(../img/down.png) no-repeat;
    vertical-align: middle;
    padding: 0 11px;
}

.uplode_file {
    display: inline-block;
    margin: 18px 0 0 0;
    width: 100%;
}

.uplode_file_logo {
    margin-top: 0px !important;
}

.terms_and_condition_section {
    display: inline-block;
    width: 100%;
    margin: 0px 0 0 -10px;
}

.afterclick_checkbox {
    display: inline-block;
    width: 100%;
    margin: 17px 0 0 0px;
}

.switch_design {
    display: inline-block;
    margin: 31px 0 0 11px;
}

.mt20 {
    display: inline-block;
    width: 100%;
    margin: 0px 0 26px 0;
}

.terms_and_condition_section .PrivateSwitchBase-input svg {
    color: #e1e1e1;
}

.shadow-bottom {
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
}

.relative.user_signuo_button .MuiAvatar-root {
    display: inline-block;
    vertical-align: middle;
    height: 33px;
    width: 33px;
    overflow: hidden;
}

.user_name {
    display: inline-block;
    vertical-align: middle;
    padding: 0 6px 0 4px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.user_name .MuiAvatar-img {
    width: 60px;
    height: 60px;
}

.relative.user_signuo_button .css-1pqm26d-MuiAvatar-img {
    border: 3px solid #fff;
    border-radius: 38px;
}

.border_bottom .MuiSlider-rail,
.border_bottom .MuiSlider-colorPrimary {
    color: #9b26b6 !important;
}

.form_left .MuiSlider-valueLabelOpen,
.form_right .MuiSlider-valueLabelOpen {
    background: transparent;
    top: 1px;
}

.form_left .MuiSlider-valueLabelLabel,
.form_right .MuiSlider-valueLabelLabel {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #9B26B6;
}

.form_left .css-1kz0hui-MuiSlider-valueLabel:before {
    display: none;
}

.border_bottom .slider {
    margin-bottom: 10px;
}

.slider_range {
    padding: 0 0px 0 10px;
}

.terms_and_condition_section svg {
    color: #dbdada;
}


.personal_data_section .css-1tcs2i1-MuiInputBase-root-MuiInput-root:before,
.personal_data_section .css-1ptx2yq-MuiInputBase-root-MuiInput-root:before {
    border-bottom: 1px solid rgb(209 209 209 / 42%);
}

.persentage {
    position: absolute;
    top: 0px;
}

.StepButton-d0-0-2-6.completed:hover {
    background-color: rgb(155 38 182);
}

.StepButton-d2-0-2-12.active:hover {
    background-color: rgb(155 38 182);
}

.personal_data_section .css-1ptx2yq-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgb(155 38 182);
}

.personal_data_section [type='text']:focus,
.personal_data_section select:focus {
    --tw-ring-inset: var(--tw-empty,
            /*!*/
            /*!*/
        );
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #ffffff;
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    border-color: #ffffff;
}

.new_header_section ul li a:hover {
    color: #9B26B6;
}

.talenter_profile_middle .StepperContainer-0-2-1 {
    padding: 24px 0 19px 0;
}

.personal_data_section .css-1tcs2i1-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgb(155 38 182);
}

.personal_data_section .css-1dmqq7i-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input:focus {
    background-color: rgb(255 255 255 / 5%);
    border-radius: 0;
}

.calender_common {
    display: inline-block;
    width: 100%;
    margin: 3px 0 0 0;
}

.day_list,
.month_list,
.year_list {
    display: inline-block;
    width: 60px;
    margin: 0 13px 0 0;
}


.personal_data_section .switch_design .MuiSwitch-switchBase.Mui-checked {
    -webkit-transform: translateX(19px);
    -moz-transform: translateX(19px);
    -ms-transform: translateX(19px);
    transform: translateX(19px)
}

.eventIcon_icon {
    display: inline-block;
    width: 60px;
    position: relative;
}

.eventIcon_icon svg {
    color: #979797;
    font-size: 26px;
}

.talenter_profile_middle .StepButton-d12-0-2-44.completed {
    background-color: rgb(155 38 182);
}

.talenter_profile_middle .StepButton-d14-0-2-48.active {
    background-color: rgb(237, 29, 36) !important;
}

.talenter_profile_middle .StepButton-d14-0-2-48.active {
    background-color: rgb(155 38 182) !important;
}

.talenter_profile_middle .StepButton-d14-0-2-48.completed {
    background-color: rgb(155 38 182);
}

.talenter_profile_middle .StepButton-d16-0-2-54.completed {
    background-color: rgb(155 38 182);
}

.talenter_profile_middle .StepButton-d18-0-2-60.completed {
    background-color: rgb(155 38 182);
}

.talenter_profile_middle .StepButton-d20-0-2-66.active {
    background-color: rgb(155 38 182);
}

.border_bottom.production_year .label_text {
    width: 34%;
}

.add_more_items .focus\:ring:focus {
    border: none;
    outline: none;
}

.talenter_profile_middle .StepButton-d8-0-2-32.completed {
    background-color: rgb(155 38 182) !important;
}

.talenter_profile_middle .StepButton-d10-0-2-38.active {
    background-color: rgb(155 38 182) !important;
}

.images_uploders {
    display: block;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    text-align: center;
}

.left_cover_images {
    display: inline-block;
    width: 100%;
    text-align: center;
    background: #E5E5E5;
    position: relative;
    /* height: 520px; */
}

.image_item {
    display: inline-block;
    width: 100%;
    background: #E5E5E5;
    height: auto;
    text-align: center;
    position: relative;
}

.image_items_bg_none {
    background: rgba(229, 229, 229, 0) !important
}


.image_items_size {
    min-height: 250px;
    min-width: 200px;
}

.image_items_min_size {
    min-height: 150px;
    min-width: 100px;
}

.images_uploader_left {
    padding-left: 1px !important;
}

.images_uploader_right {
    padding-right: 1px !important;
}

.step_buttons {
    margin: 0 auto;
    width: 716px;
}

.outer_bg_section .css-1tcs2i1-MuiInputBase-root-MuiInput-root:after {
    border-bottom: 1px solid #9b26b6;
}

.outer_bg_section .css-1ptx2yq-MuiInputBase-root-MuiInput-root:after {
    border-bottom: 1px solid #9b26b6;
}

.eventIcon_icon .css-1d6wzja-MuiButton-startIcon {
    text-align: center;
    margin: 0px;
    padding: 0px;
}

.eventIcon_icon .css-1w1rijm-MuiButtonBase-root-MuiButton-root {
    min-width: 49px;
}

.border_bottom_fixed {
    display: inline-block;
    width: 100%;
}

.border_bottom_fixed .label_text {
    display: inline-block;
    width: 64%;
}

.eventIcon_icon .MuiButton-startIcon svg {
    font-size: 25px;
}

.new_header_section {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    z-index: 100;
}

.left_slider .css-14pt78w-MuiSlider-rail {
    opacity: 0.1;
}

.add_more_button:focus {
    outline: none;
    border: none;
    --tw-ring-color: #fff;
}


.outer_bg_section .css-1tcs2i1-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before,
.outer_bg_section .css-1ptx2yq-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgb(155 38 182);
}

.outer_bg_section [type='text']:focus,
.outer_bg_section select:focus {
    border-color: #ffffff;
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #ffffff;
}

.outer_bg_section .css-1tcs2i1-MuiInputBase-root-MuiInput-root:before,
.outer_bg_section .css-1ptx2yq-MuiInputBase-root-MuiInput-root:before {
    border-bottom: 1px solid rgb(209 209 209 / 42%);
}

.outer_bg_section .css-1dmqq7i-MuiNativeSelect-select-MuiInputBase-input-MuiInput-input:focus {
    background-color: rgb(255 255 255 / 5%);
    border-radius: 0;
}

.progros_bar {
    display: inline-block;
    font-size: 9px;
    font-weight: 600;
    line-height: 10px;
    letter-spacing: 0em;
    text-align: center;
    color: #9B26B6;
}

.tooltip_contianer {
    font-family: Poppins;
    font-size: 14px;
    color: #44454D;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}

.position_abst {
    position: absolute;
    width: 54px;
}

.job_exp_cal {
    transform: translate(240px, 0px);
}

.css-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgb(155 38 182) !important;
}

.border_bottom {
    position: relative;
}

.custom_dropdown_icons.skin_color .icon__box {
    width: 19.6%;
}

.custom_dropdown_icons.eye_color .icon__box {
    width: 16.6%;
}

.icon__box:hover {
    background: #fbfbfb;
}

.custom_dropdown_icons.eye_color .dropdown__icons__list {
    width: 580px;
}

.custom_dropdown_icons.hair_color .dropdown__icons__list {
    width: 500px;
}

.outer_bg_section input:-internal-autofill-selected {
    background-color: -internal-light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important;
}

.qustion_icons {
    display: inline-block;
    width: 15px;
    height: 15px;
    background: url(../img/icon.question.png) no-repeat;
    position: absolute;
    top: 16px;
    cursor: pointer;
}

.message_incon {
    height: 24px;
    width: 24px;
    border-radius: 40px;
    background: #e1bee9;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    display: inline-block;
    text-align: center;
    color: #9B26B6;
}


.top_right_nave {
    display: inline-block;
    width: 100%;
    margin: 4px 0;
    vertical-align: top;
}

.top_right_nave ul {
    float: right;
    padding: 0px;
    margin: 0px;
}

.top_right_nave ul li {
    display: inline-block;
    padding: 0px;
    margin: 0px;
}

.top_right_nave ul li a {
    display: inline-block;
    margin: 0px;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    color: #44454D;
    text-decoration: none;
}

.top_right_nave ul li a:hover {
    color: #9B26B6;
}

.top_title h2 {
    display: inline-block;
    padding: 0px;
    margin: 0px;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
}

.top_profile_section {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    padding: 22px 0;
}

.middle_bar_section {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    background: #f4f4f4;
    padding: 33px 0;
}

.left_side_bar ul {
    float: right;
    padding: 0px;
    margin: 0px;
}

.left_side_bar ul li {
    display: inline-block;
    padding: 0px;
    margin: 0px;
    width: 100%;
}

.left_side_bar ul li a {
    display: inline-block;
    margin: 0px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    width: 100%;
    vertical-align: middle;
    padding: 11px 0;
    padding-left: 51px;
}

.data_icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url(../img/camera_icon.png) 0 0 no-repeat;
    vertical-align: middle;
    margin: 0 13px 0 0;
}

.data_icon.data_icon2 {
    background: url(../img/icon.user.svg) no-repeat;
}

.data_icon.data_icon3 {
    background: url(../img/icon_ruler.png) no-repeat;
}

.data_icon.data_icon4 {
    background: url(../img/icon_userseettings.png) no-repeat;
}

.data_icon.data_icon5 {
    background: url(../img/icon_education.png) no-repeat;
}

.data_icon.data_icon6 {
    background: url(../img/icon_videogallery.png) no-repeat;
}

.left_side_bar ul li a:hover {
    background: #E5E5E5;
    color: #9B26B6;
}

.profile_name_left h3 {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    vertical-align: middle;
}

.profile_name_left h4 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    vertical-align: middle;
}

.validations {
    display: inline-block;
    width: 33px;
    height: 33px;
    background: url(../img/icon_validated.png) 0 -2px no-repeat;
    vertical-align: middle;
}

.profile_click .MuiButton-text {
    background: url(../img/three_dots.png) center center no-repeat;
    padding: 0px;
    height: 24px;
    width: 25px;
    padding: 0px 0;
    vertical-align: middle;
}

.profile_click {
    display: inline-block;
    text-align: center;
}

.top_profile_name {
    display: inline-block;
    width: 860px;
}

.profile_name__left h3 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    display: inline-block;
}

.com__scor {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    display: inline-block;
    color: #44454D;
}

.profile_name__left {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #E5E5E5;
    padding: 0 0 12px 0;
}

.profile_compliction_scor {
    display: inline-block;
    width: 100%;
    padding: 28px 0 0px 0;
}

.profile_name__accright {
    float: right;
    vertical-align: middle;
}

.profile_name__accright label {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: right;
    color: #44454D;
    padding: 0 23px 0 0;
}

.profile_name__accright .MuiButton-root {
    background: #FFFFFF;
    /* Maroon */
    border: 1px solid #9B26B6;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #9B26B6;
    width: 80px;
    text-transform: inherit;
}

.profile_name__accright .MuiButton-root:hover {
    background: #fff;
    border: 1px solid #9B26B6;
    color: #9B26B6;
}

.profile_name__accd h3 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    display: inline-block;
    width: 100%;
}

.under_line {
    display: inline-block;
    width: 60px;
    height: 3px;
    background: #9B26B6;
    vertical-align: top;
    margin-top: 7px;
}

.upperarrow {
    display: inline-block;
    width: 18px;
    height: 15px;
    background: url(../img/upper_arrow.png) 0 2px no-repeat;
    margin: 0 0px 0 33px;
}

.act__user_profile_top {
    vertical-align: top;
    display: inline-block;
    width: 100%;
    padding: 30px 0 20px 0;
}

.list_img {
    display: inline-block;
    padding: 0 6px;
    margin-bottom: 15px;
    float: left;
    width: 20%;
}

.act__user_profile_glary .slick-dots li button:before {
    font-size: 11px;
    opacity: 1;
    color: #EDEDED;
}

.act__user_profile_glary .slick-dots li {
    margin: 0 0px;
}

.act__user_profile_glary .slick-dots li.slick-active button:before {
    color: #9B26B6;
    ;
}

.personaldata_profile_top {
    display: inline-block;
    width: 100%;
}

.outer_form_cntrol {
    display: inline-block;
    width: 100%;
}

.ach_title {
    display: inline-block;
    width: 100%;
}

.ach_title {
    display: inline-block;
    width: 100%;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    margin: 36px 0 6px 0;
}

.ongoing_project_list {
    display: inline-block;
    width: 100%;
    padding-top: 24px;
    padding-left: 40px;
}

.ongoing_project_list h4 {
    display: inline-block;
    width: 100%;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
}

.ongoing_project_list label {
    display: inline-block;
    width: 100%;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #979797;
}

.ongoing_project_list p {
    font-family: Poppins;
    font-size: 16px;
    color: #44454D;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.ongoining_projectrs .slick-dots li button {
    padding: 0px;
    padding: 0px;
}

.ongoining_projectrs .slick-dots {
    padding: 0px;
    padding: 0px;
}

.ongoining_projectrs .slick-dots li button:before {
    font-size: 11px;
}

.ongoining_projectrs .slick-dots li.slick-active button:before {
    color: #9b26b6;
    opacity: 1;
}

.ongoining_projectrs .slick-dots li button:before {
    color: #b7b7b7;
}

.ongoining_projectrs .slick-dots li {
    width: 10px;
}

.outer_bg_section .MuiFormControl-root .MuiInput-root .MuiInput-underline {
    border-bottom: 1px solid rgb(155 38 182);
}

.outer_bg_section_data .MuiInput-underline:before {
    border-bottom: 1px solid rgb(215 213 213 / 42%);
}

.outer_bg_section_data .MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgb(155 38 182);
}

/* .personal_data_section .MuiInputBase-colorPrimary.Mui-focused {
    border-bottom: 1px solid #9b26b6;
} */

.talenter_profile_middle .MuiInputBase-colorPrimary.Mui-focused::after {
    border-bottom: 1px solid rgb(155 38 182);
}

.top_profile_name .MuiInput-underline.MuiInputBase-root.Mui-focused:after {
    border-bottom: 1px solid #9b26b6;
}

.top_profile_name [type='text']:focus,
.top_profile_name select:focus {
    --tw-ring-offset-color: #f4f4f4;
    --tw-ring-color: #f4f4f4;
}

.outer_bg_section_data .MuiTabPanel-root {
    padding: 0px;
}

.outer_bg_section_data .MuiButtonBase-root {
    font-family: 'Poppins';
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #979797;
    text-transform: inherit
}

.outer_bg_section_data .MuiTabs-fixed {
    text-align: center;
}

.outer_bg_section_data .MuiBox-root {
    border-bottom: 1px solid rgb(255 255 255 / 12%);
}

.outer_bg_section_data .MuiTabs-indicator {
    background-color: rgb(155 38 182);
}

.inner_video h4 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    margin: 13px 0 0 0;
}

.inner_video label {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    margin: 0px 0 16px 0;
}

.at_time {
    color: #9B26B6;
}

.video_img img {
    max-width: 100%;
    border-radius: 14px;
    width: 100%;
    height: 100%;
}

.printShoot_placeholder img {
    max-width: 100%;
    border-radius: 14px;
    width: 100%;
    height: 100%;
}

.inner_video {
    display: inline-block;
    width: 95%;
    padding: 0px 0 20px 0;
}

.middle_bar_section .MuiBox-root {
    border: none;
}

.middle_bar_section .Mui-selected {
    color: #9b26b6 !important;
}

.middle_bar_section .MuiTabs-indicator {
    background-color: #9b26b6;
}

.middle_bar_section .top_profile_name [type='text']:focus,
.top_profile_name select:focus {
    --tw-ring-offset-color: #fafafa;
    --tw-ring-color: #fafafa;
}

.intro_video_section .slick-dots li button {
    padding: 0px;
    padding: 0px;
}

.intro_video_section .slick-dots {
    padding: 0px;
    padding: 0px;
}

.intro_video_section .slick-dots li button:before {
    font-size: 11px;
}

.intro_video_section .slick-dots li.slick-active button:before {
    color: #9b26b6;
    opacity: 1;
}

.intro_video_section .slick-dots li button:before {
    color: #b7b7b7;
}

.intro_video_section .slick-dots li {
    width: 10px;
}

.remove_icon {
    display: inline-block;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #9B26B6;
    background: url('../img/remove_icon.svg') top left no-repeat;
    padding: 2px 0 0 32px;
}
/* Ilustration */


.addMore_btn{
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #9B26B6;
    padding: 2px 0 0 32px;
    margin: 9px 0 0 0;
    vertical-align: center;
}

.addMore_btn.remove_padding {
    padding: 2px 0 0 0 !important;
}

.short_flime .inner_video {
    padding: 0px 0 20px 0;
}

.short_flime {
    display: inline-block;
    width: 100%;
    padding: 30px 0 0;
}

.add_more_section {
    display: inline-block;
    width: 100%;
    padding: 20px 0 0;
}

.add_more_section .addmore {
    display: inline-block;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #9B26B6;
    background: url('../img/add_more.svg') top left no-repeat;
    padding: 2px 0 0 32px;
}

.das_list {
    display: inline-block;
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    min-height: 120px;
    padding: 28px 0;
    margin-bottom: 28px;
    transition: .3s;
}

.das_list:hover {
    box-shadow: 0px 18px 16px rgb(221 221 221 / 40%);
    transform: scale(1.05, 1.05);
}

.right_new_icon label {
    display: inline-block;
    width: 100%;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #979797;
}

.right_new_icon b {
    display: inline-block;
    width: 100%;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
}

.left_new_icon {
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    text-align: center;
}

.left_new_icon img {
    display: inline-block;
    max-width: 100%;
    vertical-align: middle;
    text-align: center;
}

.top_profile_section_bot_list {
    display: inline-block;
    width: 100%;
    background: #f4f4f4;
    padding-top: 57px;
}

.das_list.last_box_section {
    padding: 38px 0;
}

.bottom_space {
    display: inline-block;
    width: 100%;
    min-height: 50px;
}

.top_search_section {
    display: inline-block;
    width: 100%;
    background: #FFFFFF;
    border-radius: 5px;
    min-height: 160px;
    padding: 0 39px 40px;
}

.table_count_search_section {
    display: inline-block;
    width: 100%;
    margin: 15px 0 16px 0;
}

.table_count_search_section h2 {
    display: inline-block;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
}

.nwswitch {
    display: inline-block;
    padding: 0 0 0 18px;
}

.nwswitch_top {
    display: inline-block;
    padding: 0 0 0 18px;
    /* line-height: 60px; */
}

.nwswitch_top_search {
    display: inline-block;
    padding: 0 0 0 18px;

    /* line-height: 60px; */
}

.table_count_search_section .MuiTypography-root {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
}

.table_count_search_section .MuiButton-outlined {
    float: right;
    border: 1px solid #9B26B6;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    height: 40px;
    padding: 0 25px;
    letter-spacing: 0em;
    text-align: center;
    color: #9B26B6;
    text-transform: inherit;
    background: #fff;
    margin: 10px 0 0 0;
}

.top_search_section .MuiDataGrid-columnHeaderTitle {
    font-family: Poppins;
    font-size: 12px;
    color: #44454D;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
}

.top_search_section .MuiDataGrid-cellContent {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
}

.top_search_section .css-f3jnds-MuiDataGrid-columnHeaders {
    border-bottom: 1px solid rgb(239 239 239);
}

.top_auto_search {
    display: inline-block;
    width: 100%;
}

.top_profile_section_bot_list .MuiFormControl-root {
    width: 100%;
}

.top_search_section_table {
    display: inline-block;
    width: 100%;
    background: #FFFFFF;
    border-radius: 5px;
    min-height: 160px;
}

.submit_buttons .MuiButton-containedPrimary {
    width: 100%;
    background: #9B26B6;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    height: 40px;
    text-transform: inherit;
    margin: 41px 0 0 0;
}

.submit_buttons_list .MuiButton-containedPrimary {
    width: 100%;
    background: #9B26B6;
    border: 1px solid #9B26B6;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    padding: 0 25px;
    letter-spacing: 0em;
    text-align: center;
    height: 38px;
    text-transform: inherit;
    margin: 0 0 0 0;
    color: #fff;

}

.submit_buttons_list .MuiButton-containedPrimary:hover {
    background: #fff;
    color: #9B26B6;
}

.submit_buttons .MuiButton-containedPrimary:hover {
    background: #222;
}

.top_search_section .submit_buttons .MuiButton-containedPrimary {
    width: 100%;
    background: #9B26B6;
    border: 1px solid #9B26B6;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    height: 40px;
    text-transform: inherit;
    margin: 41px 0 0 0;
    color: #fff;
}

.top_search_section .submit_buttons .MuiButton-containedPrimary:hover {
    background: #fff;
    border: 1px solid #9B26B6;
    color: #9B26B6;
}




.MuiInput-underline:before {
    border-bottom: 1px solid rgb(215 213 213 / 42%) !important;
}
.newwidth .custom_dropdown_outer .MuiInput-underline:before{
    border-bottom: 1px solid #9B26B6 !important;
}
.bottom_table {
    display: inline-block;
    width: 100%;
}

.top_title {
    display: inline-block;
    width: 100%;
}

.button_right {
    float: right;
    vertical-align: top;
    margin: 20px 0;
}

.button_right .MuiButton-containedPrimary {
    background: #9B26B6;
    border: 1px solid #9B26B6;
    box-shadow: 0px 2px 5px rgb(0 0 0 / 5%);
    border-radius: 5px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    padding: 0 20px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    height: 40px;
    text-transform: inherit;

    color: #fff;
}

.button_right .MuiButton-containedPrimary:hover {
    background: #fff;
    border: 1px solid #9B26B6;
    color: #9B26B6;
}

.border_bottom .MuiInputBase-formControl {
    width: 100%;
}

.border_bottom select {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
}


.admin_panel_profile .MuiInput-input.MuiInputBase-input {
    height: 23px;
    font-size: 14px;
    font-weight: 400;
}

li.MuiMenuItem-root:not(#account-menu),
div#custom_select,
.MuiInputBase-input,
.submit_buttons_bottom button {
    font-size: 14px;
    font-weight: 400;
}

.left_user_profile {
    display: inline-block;
    width: 100%;
}

.job_post_uplo h2 {
    display: inline-block;
    width: 100%;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
}

.job_post_uplo p {
    display: inline-block;
    width: 100%;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    margin-bottom: 13px;
}

.up_profile_section {
    display: inline-block;
    width: 100%;
    margin-top: 15px;
}

.submit_buttons_popup {
    display: inline-block;
    width: 100%;
    margin-top: 22px;
}

.right_common_button {
    float: right;
}

.submit_buttons_popup div .MuiButton-root.MuiButton-contained.admin_cancel,
.submit_buttons_popup div .MuiButton-root.MuiButton-contained .admin_cancel:hover {
    font-family: Poppins;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    background: #E5E5E5;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    padding: 0 24px;
    color: #979797;
    text-transform: inherit;
}

.submit_buttons_popup .admin_preview {
    height: 40px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    background: #9B26B6;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: inherit;
    padding: 0 24px;
    margin-left: 17px;
    border: 1px solid #9B26B6;
}

.submit_buttons_popup .admin_save {
    height: 40px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    /* White */
    padding: 0 24px;
    background: #FFFFFF;
    /* Maroon */
    border: 1px solid #9B26B6;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    border: 1px solid #9B26B6;
    color: #9B26B6;
    text-transform: inherit;
    cursor: pointer;
}

.submit_buttons_popup .admin_preview:hover {
    background: #fff !important;
    color: #9B26B6 !important;
    border: 1px solid #9B26B6;
}

.submit_buttons_popup .admin_save:hover {
    background: #fff;
    color: #9B26B6;
    border: 1px solid #9B26B6;
}

.outer_bg_section_padd {
    display: inline-block;
    width: 100%;
    padding-top: 30px;
}

.job_datepicker {
    width: 200px;
}

.mt20 textarea:focus {
    outline: none;
    border: none;
    --tw-ring-color: #fff;
}

.audit_preview_common {
    display: inline-block;
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    min-height: 190px;
    padding: 23px 28px;
}

.left_pro_info b {
    display: inline-block;
    width: 100%;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #101820;
}

.left_pro_info label {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #9B26B6;
    display: flex;
    flex-wrap: wrap;
}

.left_pro_info label .cat_info {
    color: #979797;
    position: relative;
    padding: 0 0 0 24px;
}

.left_pro_info label .cat_one_info {
    color: #979797;
    position: relative;
    padding: 0 0 0 0px;
}

.left_pro_info label .cat_info_label {
    color: #020202;
    position: relative;
    padding: 0 0 0 24px;
}

.cat_info_label:before {
    position: absolute;
    left: 9px;
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 50px;
    background: #44454D;
    top: 10px;
}

.cat_info:before {
    position: absolute;
    left: 9px;
    content: '';
    width: 4.5px;
    height: 5px;
    border-radius: 50px;
    background: #44454D;
    top: 10px;
}

.right_but_info .applay_butt {
    background: #9B26B6;
    /* Field Border Grey */
    border: 1px solid #9B26B6;
    border-radius: 5px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    height: 37px;
    text-transform: inherit;
    float: right;
    padding: 0 31px;
    box-shadow: none;
}

.right_but_info .disabled {
    border: none !important;
}

.right_but_info .applay_butt:hover {
    background: #fff;
    border: 1px solid #9B26B6;
    color: #9B26B6;
}

.left_us_icon {
    display: inline-block;
    width: 38px;
    vertical-align: top;
    margin: 9px 0 0 0;
    text-align: center;
}

.left_us_info {
    display: inline-block;
    width: 108px;
}

.left_us_info strong {
    display: inline-block;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    width: 100%;
    margin: 5px 0 0 0;
    vertical-align: top;
}

.left_us_info p {
    display: inline-block;
    width: 100%;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    vertical-align: top;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
}

.left_pro_info {
    display: inline-block;
    vertical-align: top;
}

.left_us_icon img {
    display: inline-block;
}

.slect_list {
    display: inline-block;
    width: 100%;
    margin-top: 15px;
}

.message_info b {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    padding: 0px 0 15px 0;
    display: inline-block;
}

.message_info p {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
}

.user_preview_info {
    display: inline-block;
    width: 100%;
    padding-top: 7px;
}

.mod_left__sec {
    display: inline-block;
    width: 100%;
    padding-left: 8px;
}

.mod_left__sec h2 {
    display: inline-block;
    width: 100%;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
}

.mod_left__sec strong {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    color: #9B26B6;
    line-height: 18px;
    margin-bottom: 9px;
    display: inline-block;
    letter-spacing: 0em;
    text-align: left;
}

.left_user_profile img {
    max-width: inherit;
}

.icon_left_lis {
    display: inline-block;
    width: 40px;
    vertical-align: top;
    margin: 10px 0 0 0;
}

.icon_left_info b {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    display: inline-block;
    width: 100%;
}

.icon_left_info label {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    color: #44454D;
    letter-spacing: 0em;
    text-align: left;
    display: inline-block;
    width: 100%;
}

.icon_left_info {
    display: inline-block;
    padding: 0 18px 0 0px;
}

.company_list {
    display: inline-block;
    width: 100%;
    padding-top: 5px;
}

.icon_left_info h3 {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    padding: 0px 0 15px 0;
    display: inline-block;
}

.icon_left_info p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}

.bot_space {
    display: inline-block;
    width: 100%;
    vertical-align: top;
}

.right_but_info .copy_link_button {
    font-family: Poppins;
    height: 40px;
    /* font-size: 14px;font-weight: 600;background: #F1F1F1 url('../img/icon_extlink.png') 93% 10px no-repeat; */
    border-radius: 5px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    padding: 0 38px 0 14px;
    color: #979797;
    text-transform: inherit;
    float: right;
    box-shadow: none;
}

/* .right_but_info .copy_link_button:hover{background: #F1F1F1 url('../img/icon_extlink.png') 93% 10px no-repeat;} */
.left_user_profile_prvi {
    display: inline-block;
    width: 100%;
    background: #F4F4F4;
    border-radius: 10px;
    min-height: 137px;
    vertical-align: middle;
    text-align: center;
    line-height: 135px;
}

.left_user_profile_prvi img {
    display: inline-block;
    vertical-align: middle;
}

.right_but_info .link_list {
    font-family: Poppins;
    color: #9B26B6;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    display: inline-block;
    width: 100%;
    padding: 12px 0 0 0px;
    text-decoration: underline;
}

.common_title {
    display: inline-block;
    width: 100%;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    padding: 34px 0 16px 0;
}

.border_bottom_admin {
    display: inline-block;
    background: #9B26B6;
    height: 3px;
    width: 60px;
    vertical-align: top;
    margin: 5px 0 0 0;
}

.common_title {
    vertical-align: top;
}

.job_list_common .slect_list {
    margin-top: 6px;
}

.job_list_common .message_info b {
    padding: 0px 0 6px 0;
}

.job_list_common .left_us_info {
    width: 104px;
}

.job_list_common .left_us_info p {
    font-size: 10px;
}

.job_list_common .user_preview_info {
    margin: 0px;
    padding: 0px;
}

.message_info .link_list {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    color: #9B26B6;
    text-decoration: underline;
}

.job_list_common .right_but_info .link_list {
    padding: 12px 0 0 0px;
}

.right_but_info .det_butt {
    background: #fff;
    /* Field Border Grey */
    border: 1px solid #9B26B6;
    border-radius: 5px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #9B26B6;
    height: 37px;
    text-transform: inherit;
    float: right;
    margin-top: 10px;
    padding: 0 31px;
    box-shadow: none;
    width: 100%;
    padding: 0 8px;
}

.job_list_common .right_but_info .applay_butt {
    width: 100%;
    padding: 0 8px;
}

.right_but_info .det_butt:hover {
    background: #9B26B6;
    color: #fff;
}

.pagenation_common {
    display: inline-block;
    width: 100%;
    margin-top: 22px;
}

.top_search_section .MuiIconButton-root {
    padding: 0px;
}

.MuiAutocomplete-popper ul li a {
    font-size: 14px;
    color: #44454D;
}

.top_search_section_table .MuiPaper-root {
    box-shadow: none;
}

/* .table_count_search_section .MuiSwitch-root {
    width: 45px;
} */

.top_search_section_table table thead th {
    padding: 10px 16px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    color: #44454D;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    border-bottom: 1px solid #E5E5E5;
}

.top_search_section_table table tbody td {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    padding: 10px 16px;
    border-bottom: 1px solid #E5E5E5;
}

.top_search_section_table table tbody td svg {
    font-size: 18px;
}

.top_search_section_table table tbody td .MuiIconButton-root {
    padding: 0px 3px;
}

.top_search_section_table table tbody td:first-child svg {
    font-size: 1.5rem;
}

.top_profile_section_bot_list [type='search']:focus,
.top_profile_section_bot_list [type='text']:focus,
.top_profile_section_bot_list [type='email']:focus,
.top_profile_section_bot_list [type='password']:focus {
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #ffffff;
    border-color: #ffffff;
}

.top_profile_section_bot_list .MuiInput-underline.MuiInputBase-root.Mui-focused:after {
    border-bottom: 1px solid #9b26b6;
}

.action__btn {
    background: #E5E5E5;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    padding: 8px 30px;
    display: block;
    width: auto;
    text-align: center;
    max-width: 300px;
    cursor: pointer;
}

.success__btn {
    background: #9B26B6;
    box-shadow: 0px 2px 5px rgb(0 0 0 / 5%);
    color: #ffffff;
}

.btn__container {
    display: flex;
    width: 100%;
    justify-content: space-around;
    padding-bottom: 40px;
}

#firrt-dialog-description p {
    display: block;
    margin: 0 auto;
    margin-bottom: 40px;
}

#firrt-dialog-description b {
    font-weight: bold;

}

p#firrt-dialog-description {
    text-align: center;
    font-family: 'Poppins';
    color: #44454D;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
    display: block;
}

.first_alert__title {
    text-align: center;
    color: #9B26B6;
    font-family: 'Poppins' !important;
    font-weight: 600 !important;
    font-size: 24px !important;
    padding-top: 40px !important;
}

.custom_dropdown .MuiSelect-select {
    background: transparent !important;
}

.custom_dropdown .MuiInput-root::after {
    border-bottom: 0 !important;
}

li.MuiMenuItem-root:not(#account-menu),
div#custom_select,
.MuiInputBase-input,
.submit_buttons_bottom button {
    font-size: 14px;
    font-family: 'Poppins' !important;
    font-weight: 400;
}

.custom_dropdown .MuiFormControl-root {
    margin: 0;
    width: 100%;
}

li.MuiMenuItem-root:not(#account-menu),
.MuiInputBase-input {
    font-size: 14px;
    font-family: 'Poppins' !important;
    font-weight: 400;
    text-align: left;
}

.MuiFormControl-root {
    width: 100%;
}

.MuiInput-underline.MuiInputBase-root.Mui-focused:after {
    border-bottom: none;
}

.MuiInputBase-colorPrimary.Mui-focused {
    border-bottom: none;
}

.MuiInput-underline:before {
    border-bottom: 1px solid rgb(215 213 213 / 42%);
}

.MuiInput-root.MuiInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgb(155 38 182);
}

[type='text']:focus {
    --tw-ring-offset-color: #f4f4f4;
    --tw-ring-color: #f4f4f4;
}

.text__input_dialog .MuiFormControl-root {
    margin-top: 0;
}

.custom_dropdown .MuiFormLabel-filled {
    display: none;
}

.custom_dropdown .MuiInputLabel-root {
    text-transform: capitalize;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    font-family: 'Poppins' !important;
    font-weight: 400;
}

li.MuiMenuItem-root:not(#account-menu) {
    width: 100%;
    text-align: left !important;
}

.top_search_section .custom_dropdown {
    margin-top: -15px;
}

.outer_popup_country .lable_list {
    display: inline-block;
    width: 100%;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    color: #44454D;
    text-align: left;
    display: inline-block;
    width: 100%;
    vertical-align: middle;
    padding: 0;
}

.outer_popup_country .MuiInput-root.MuiInput-underline {
    width: 100%;
    margin: 0px;
}

.outer_popup_country .MuiInput-input.MuiInputBase-input {
    width: 100%;
    font-size: 16px;
}

.outer_popup_country .MuiFormHelperText-root.MuiFormHelperText-sizeMedium {
    font-size: 12px;
    color: #FF0000;
    margin: 0px;
    padding: 0px;
}

.common_popup_list {
    display: inline-block;
    width: 100%;
    padding: 9px 0;
}

.outer_popup_country [type='checkbox'],
.outer_popup_country [type='radio'] {
    color: #9b26b6;
}

.outer_popup_country [type='checkbox']:focus,
.outer_popup_country [type='radio']:focus {
    --tw-ring-offset-color: #fff;
    --tw-ring-color: #9b26b6;
}

.outer_popup_country .submit_button {
    background: #fff;
    border: 1px solid #9B26B6;
    text-transform: inherit;
    font-weight: 600;
    font-size: 14px;
    height: 41px;
    color: #9B26B6;
    padding: 0 26px;
    border-radius: 4px;
}

.outer_popup_country .cancel_button {
    background: #9B26B6;
    border: 1px solid #9B26B6;
    text-transform: inherit;
    font-weight: 600;
    font-size: 14px;
    height: 41px;
    color: #fff;
    padding: 0 26px;
    border-radius: 4px;
}

.outer_popup_country .cancel_button:hover {
    color: #9B26B6;
}

.outer_popup_country [type='text']:focus {
    --tw-ring-offset-color: #ffffff;
    --tw-ring-color: #ffffff;
}

.outer_popup_country .MuiFormLabel-root.MuiFormLabel-colorPrimary.Mui-focused {
    color: #44454D;
}

.outer_popup_country .MuiTypography-root.MuiTypography-h6 {
    font-size: 20px;
    font-weight: bold;
    color: #44454D;
}

.outer_popup_country .MuiDialogActions-root.MuiDialogActions-spacing {
    margin-bottom: 20px;
    padding: 24px;
}

.outer_popup_country .common_popup_list .MuiFormLabel-root span {
    padding: 0 10px 0 0;
}

.top_search_section_table table tbody td .items-center button svg {
    padding: 0px;
    margin: 0px;
    color: #979797;
    font-size: 21px;
}

.table_count_search_section .MuiButton-outlined:hover {
    background: #9B26B6;
    color: #fff;
    border-color: #9B26B6;
}

.country_dropdown label {
    margin-top: -20px;
    /* line-height: 36px; */
    height: bold;

}

.MuiTablePagination-root p {
    font-family: Poppins;
}

.left_user_profile img {
    width: 100%;
}

.MuiAlert-filledSuccess {
    background: #9B26B6 !important;
}

.main_bg {
    background: #9b26b6;
}

.common_popup_list .MuiFormHelperText-root.MuiFormHelperText-sizeMedium {
    display: inline-block;
    width: 100%;
    padding: 9px 0;
    font-size: 12px;
    color: #FF0000;
}

.common_popup_list .MuiInput-input.MuiInputBase-input {
    width: 100%;
    font-size: 16px;
}

.top_heading_content h2 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #9B26B6;
    display: inline-block;
    width: 100%;
}

.top_heading_content label {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    color: #44454D;
    display: inline-block;
    margin: 25px 0 0 0;
}

.top_heading_content .color_change {
    font-weight: 600;
    color: #9B26B6;
}

.top_heading_content {
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 17px 0 0 0;
}

.opt_left {
    display: inline-block;
    width: 40px;
    margin: 0 4px;
}

.top_heading_content .MuiInput-root.MuiInput-underline:after {
    border-color: #9B26B6;
}

.top_heading_content [type='text']:focus {
    --tw-ring-offset-width: 0px;
    --tw-ring-offset-color: #fff;
    /* --tw-ring-color: #ffffff; */
    --tw-ring-color: transparent !important;
}

.top_heading_content [type='text'] {
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
}

.submit_buttons_popup {
    display: inline-block;
    width: 100%;
    padding: 0;
    margin: 23px 0 3px 0;
}

.submit_buttons_popup .MuiButton-root.MuiButton-outlined {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #979797;
    float: left;
    border: 1px solid #E5E5E5;
    border-radius: 5px;
    height: 40px;
    background: #E5E5E5;
    text-transform: inherit;
    padding: 0 52px;
}

.submit_buttons_popup .MuiButton-root.MuiButton-contained {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #979797;
    border-radius: 5px;
    height: 40px;
    background: #9B26B6;
    padding: 0 52px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    color: #fff;
    float: right;
    text-transform: inherit;
}

.left_user_subName {
    color: #979797;
    font-size: 14px;
    font-weight: 600;
}

.left_us_Aud {
    display: inline-block;
}

.left_us_Aud strong {
    display: inline-block;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    width: 100%;
    margin: 5px 0 0 0;
    vertical-align: top;
}

.left_us_Auditions {
    display: flex;
}

.left_us_Auditions .link_list {
    font-family: Poppins;
    color: #9B26B6;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    width: 100%;
    padding: 0 5px 0 0;
    text-decoration: underline;

}

.left_us_Aud_note input {
    font-size: 12px;
    color: #44454D;
}

.left_pro_Dec {
    padding: 10px 0;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #44454D;
}

.left_pro_info .left_pro {
    font-weight: 600;
    color: #979797;
}

.left_us_count {
    font-weight: 600;
    font-size: 18px;
    color: #9B26B6;
}

.right_but_info .applay_butt_gray {
    width: 100%;
    padding: 0 8px;
}

.right_but_info .applay_butt_gray {
    background: #44454D;
    /* Field Border Grey */
    border: 1px solid #44454D;
    border-radius: 5px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #fff;
    height: 37px;
    text-transform: inherit;
    float: right;
    padding: 0 5px;
    box-shadow: none;
    margin-top: 10px;
}

.right_but_info .applay_butt_gray:hover {
    background: #fff;
    border: 1px solid #44454D;
    color: #44454D;
}

.card_border {
    border: #8D16A9 3px solid;
}

.link_list .link_list_play {
    padding-left: 5px;
}

.bgLightGrey {
    background-color: #E5E5E5;
}

.bgLightWhite {
    background-color: #F4F4F4;
}

p.noti__text {
    font-size: 12px;
    font-weight: 400;
    margin: 0 !important;
}

.relative.noti__list ul li {
    margin: 0;
}

p.noti__text.active {
    color: #9B26B6 !important;
}

.padding-0 {
    padding: 0 !important;
}

.templateDropdown {
    border: none !important;
    background-attachment: fixed;
}

.bgWhite-f4 {
    background-color: #f4f4f4;
}

.bgWhite-fa {
    background-color: #fafafa;
}

.bgWhite-e5 {
    background-color: #E5E5E5;
}

.view_profile .MuiButton-contained {
    display: inline-block;
    background: #9B26B6;
    font-family: Poppins;
    padding: 0 23px;
    margin: 0 16px 0 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    border: 1px solid #9B26B6;
    height: 36px;
    text-transform: inherit;
}

.view_profile .MuiButton-contained:hover {
    background: #fff;
    color: #9B26B6;
}

.view_profile {
    display: inline-block;
    margin: 0 0px 0 22px;
}

.unfave_icon {
    display: inline-block;
    background: url('./../img/heart_unfav.svg') no-repeat;
    width: 30px;
    height: 30px;
    cursor: pointer;
    vertical-align: top;
    margin: 4px 0 0 0;
}

.unfave_icon_fave_icons {
    float: right;
}

.action_button .MuiButton-contained {
    display: inline-block;
    background: #9B26B6;
    font-family: Poppins;
    padding: 0 23px;
    margin: 0 16px 0 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    border: 1px solid #9B26B6;
    height: 36px;
    text-transform: inherit;
}

.action_button {
    display: inline-block;
}

.action_button .MuiButton-contained:hover {
    background: #fff;
    border: 1px solid #9B26B6;
    color: #9B26B6;
}

.action_button .MuiButton-outlinedPrimary {
    display: inline-block;
    background: #fff;
    font-family: Poppins;
    padding: 0 23px;
    margin: 0 16px 0 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    border: 1px solid #9B26B6;
    height: 36px;
    text-transform: inherit;
    color: #9B26B6;
}

.action_button .MuiButton-outlinedPrimary:hover {
    background: #9B26B6;
    border: 1px solid #9B26B6;
    color: #fff;
}

.drop_down_menu_list ul li a {
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #44454D;
    vertical-align: middle;
    padding: 7px 20px;
    display: inline-block;
    width: 100%;
}

.drop_down_menu_list ul li {
    display: inline-block;
    width: 100%;
}

.drop_down_menu_list ul {
    display: inline-block;
    width: 100%;
}

.drop_down_menu_list {
    position: absolute;
    top: 0px;
    right: 0px;
    display: none;
    width: 180px;
    background: #FFFFFF;
    padding: 11px 0;
    /* 5px Shadow */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
}

.profile_click:hover .drop_down_menu_list {
    display: block;
}

.drop_down_menu_list ul li a img {
    display: inline-block;
}

.drop_down_menu_list {
    text-align: left;
}

.middle_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    vertical-align: top;
    background: #f4f4f4;
    padding: 33px 0;
}

.create_card {
    background-color: #fff;
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.create_card>.input_area {
    width: 90%;
}

.view_profile .MuiButton-contained {
    margin: 0;
}

.createProject_title {
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #44454D;
}

.createProject_subtitle {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #44454D;
}

.character_label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 10px;
    text-align: center;
}

.character_label_active {
    color: #9B26B6;

}

.character_label_inactive {
    color: #979797;
}

.character_label_completed {
    color: #44454D;
}

.character_title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600 !important;
    font-size: 24px;
    line-height: 20px;
    text-transform: capitalize;
    color: #9B26B6;
}

.character_sub_title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #44454D;
}

.character_link {
    font-family: 'Poppins';
    font-style: normal;
    font-size: 12px;
    line-height: 18px;
    color: #9B26B6;
}

.character_action>img {
    height: 24px;
}

.createProject_button {
    justify-content: center !important;
}

.css-zw3mfo-MuiModal-root-MuiDialog-root .css-hz1bth-MuiDialog-container .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    padding: 27px;
}

.back_button .MuiButton-outlinedPrimary {
    display: inline-block;
    background: #fff;
    font-family: Poppins;
    padding: 0 23px;
    margin: 0 16px 0 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    border: 1px solid #E5E5E5;
    height: 36px;
    text-transform: inherit;
    color: #44454D;
}

.back_button .MuiButton-outlinedPrimary:hover {
    border: 1px solid #E5E5E5;
    color: #44454D;
    background: #fff;
}

.submit_buttons_popup .MuiButton.slider_range span.MuiSlider-thumbColorPrimary {
    color: #9b26b6;
    width: 15px;
    height: 15px;
}

.form_left .MuiSlider-v-root.MuiButton-outlined.addAution_saveBtn {
    background-color: #fff;
    color: #9B26B6;
    border: #9B26B6 1px solid;
}

.submit_buttons_popup .MuiButton-root.MuiButton-outlined.addAution_saveBtn {
    background-color: #fff;
    color: #9B26B6;
    border: #9B26B6 1px solid;
}

.submit_buttons_popup .MuiButton-root.MuiButton-outlined.addAution_saveBtn:hover {
    background-color: #9B26B6;
    color: #fff;
}

.form_err {
    font-family: Poppins;
    font-size: 12px;
    color: #FF0000 !important;
    line-height: 1.66;
    letter-spacing: 0.03333em;
}

.left_slider {
    float: left;
    width: 67%;
    margin: 9px 0 0 0;
}

.left_slider .MuiSlider-rail {
    opacity: 0.1;
}

.slider_range {
    padding: 0 0px 0 0px;
}

.slider_range span.MuiSlider-thumbColorPrimary {
    color: #9b26b6;
    width: 15px;
    height: 15px;
}

.form_left .MuiSlider-valueLabelLabel,
.form_right .MuiSlider-valueLabelLabel {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    color: #9B26B6;
}

/* for media css */
.sm_editIcon {
    display: none;
}

.slick-dots li button:before,
.slick-next:before,
.slick-prev:before {
    color: #9B26B6;
}

.Moblie_edit_img {
    height: 2rem;
    width: 2rem;
    cursor: pointer;
}

.Moblie_edit_img>img {
    height: 100% !important;
}

#PhotoGrallery .slick-next {
    right: 17px;
    top: 46%;
}

#PhotoGrallery .slick-prev {
    left: 0px;
    top: 46%;
    z-index: 2;
}

#PastWorks .slick-next,
#EducationandSkills .slick-next {
    right: 38px;
    top: 38%;
}

#PastWorks .slick-prev,
#EducationandSkills .slick-prev {
    left: 0px;
    top: 38%;
    z-index: 2;
}
#PastWorks .MuiTabs-scrollButtons {
    color: #9b26b6;
}
#PastWorks .MuiTabs-scrollButtons svg{
    width: 85%;
    height: 100%;
}


.slick-next:before,
.slick-prev:before {
    font-size: 35px;
}

.infoText {
    display: inline-block;
    color: #9B26B6;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    width: 100%;
    text-align: start;
}

.display_Submitbuttons {
    display: inline-block !important;
}

.submit_buttons_bottom .upload_img_button {
    height: 40px;
    width: fit-content;
    border: solid 1px #9B26B6;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    text-transform: inherit;
    padding: 0px 15px;
    background: #FFFFFF;
    float: right;
    color: #9B26B6;
}

.submit_buttons_bottom .upload_img_button:hover {
    background: #FFFFFF;
}

.info_text_disable {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #9B26B6;
}

.verify_mobile_button .MuiButton-root {
    border: 1px solid #9B26B6;
    animation-timing-function: ease-out;
    animation-duration: 300ms;
    color: #9B26B6;
    align-items: flex-start;
    padding: 8px 15px 8px 12px;
    background: #fff;
    height: 36px;
    line-height: 19px;
    text-transform: inherit;
    width: 106px;
    border-radius: 4px;
    float: right;
    vertical-align: middle;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
}

.save_button {
    height: 40px;
    background: #9B26B6 !important;
    color: #fff !important;
}

.inner_video div {
    width: 100% !important;
    height: 241px !important;
    /* max-width: 422px; */
}

.loader_height {
    height: 78vh;
}

.company_logo {
    max-height: 160px;
    max-width: 132px;
    object-fit: contain;
}

.company_logo img {
    max-width: 132px;
    max-height: 160px;
    object-fit: contain;

}

.icons_list {
    display: inline-block;
    vertical-align: top;
    width: 30px;
}

.icons_list .tick_img {
    height: 25px;

}

.recruiter_text {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #44454D;
    word-break: break-all;

}

/* notification -------------------------------------------------------------------------------- */
#notificationList .talent_name {
    color: #9B26B6 !important;
    font-weight: 500;
}

#notificationList a {
    color: #9B26B6;
    cursor: pointer;
}

.submit_notVaild {
    opacity: 0.5;
}

.modal-open {
    overflow: auto !important;
    padding-right: 0px !important;
}

p.dropdown_placeholder_line,
label#disable-close-on-select-label-list {
    font-size: 14px;
    color: #E5E5E5 !important;
    position: absolute;
    font-weight: 400;
    /* line-height: 2.1; */

}

.dropDown_icon .MuiIconButton-root {
    padding: 0 !important;
}

/* hover menu------->  gallery  */
.imgeOption_click {
    z-index: 10;
    top: 10px;
    /* right: 18px; */
    right: -22px;
    cursor: pointer;
    position: absolute;
}

.imgeOption_click .photo_action_contianer {
    display: block;
}

.imgeOption_click img {
    display: inline-block;
    position: relative;
    top: 6px;
    left: 5px;
}

/* edit gallery --> */
#edit_imageUpload .image_items_size {
    min-height: 196px;
    min-width: 75px;
}

.disabled_label_text {
    color: #979797 !important;
}

.disabled_border_bottom [type='text'] {
    color: #979797;
}

.form_left.left_move_mt {
    margin: 34px 0 0 0;
}

.form_left.left_move_mt .MuiSlider-rail {
    opacity: 0.1;
}

.menu_items {
    position: relative;
}

.menu_items {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 35px;
    text-transform: capitalize;
    color: #44454D;
}

.menu_items.active_item {
    color: #9B26B6;
}

.active_menu_bar {
    position: absolute;
    border: 3px solid #9B26B6;
    background-color: #9B26B6;
    width: 100%;
    bottom: -19px;
}

#uploadDialog .MuiPaper-root {
    background-color: rgba(0, 0, 0, 0);
    box-shadow: none
}

.profileDropDownButton {
    background: #9B26B6;
    color: #fff;
    padding: 0px 9px;
    border-radius: 32px;
    min-height: 42px;
}

#cell_color {
    color: #9B26B6 !important;
}

#tables select:focus {
    border-color: #fff;
    box-shadow: none;
    background-color: #fff;
}


.userLog_container .contentText{
    font-family: 'Poppins';
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 21px;
   color: #44454D;
   
   }
   
   .userLog_container .time{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #979797;}
   
   .userLog_container .BoldText{
     font-weight: 600  !important;
    }

    #userLog .talent_name {
        color: #9B26B6 !important;
        font-weight: 500;
    }

    .not__vaild_img {
        /* opacity: 0.3; */
        filter: opacity(50%);
    }

    .time_count {
    text-align: right;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: right;
    color: #979797;
}
.resend_but a {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #9B26B6;
    background: url('../img/iconrefresh.png') no-repeat;
    padding: 1px 0 0 31px;
}

.resend_but {
    display: inline-block;
    text-align: center;
}
.relode_the_buttons {
    display: inline-block;
    width: 100%;
    margin: 34px 0 8px 0;
    vertical-align: middle;
    text-align: left;
}
#hideInput input{
    width: 0;
}

.chartAtLimit {
    font-family: 'Poppins';
font-style: normal;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}
.charColor {
    color: #44454D;
}

.printShootCount {
    font-family: Poppins;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: end;
    color: #44454D;
    font-size: 14px;
    color: #979797;
    margin-top: 2px;
}
.audiotnTypeArea {

    width: 75px !important;
}
.errText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    color: #FF0000;
}

.otp_error_value input,
.otp_error_value select,
.otp_error_value .MuiInputBase-root:before,
.otp_error_value input,
.otp_error_value select,
.otp_error_value .MuiInputBase-root::after {
    border-color: #FF0000 !important;
}


.image_loader .MuiDialog-container .MuiPaper-root {
    background-color: transparent;
    box-shadow: none;
}

.iiz__img {
    max-height: 55vh;
}


.linkFont{
    font-family: 'Poppins' !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #9B26B6;
    box-shadow: none;
    white-space: nowrap;
    overflow: hidden;
}
#hideInput input{
    width: 0;
}
.MuiDataGrid-columnHeaderTitle  {
    font-family: 'Poppins' !important;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    color: #44454D !important;
    
  }
.admin_panel_audition_details .value_broder input,  .admin_panel_audition_details .value_broder select{border:none!important}
.cr_prtitle p.MuiFormHelperText-root {color:#FF0000;font-family: Poppins;font-size: 12px;line-height: 1.66;letter-spacing: 0.03333em;
}

.border_bottom .MuiInputBase-colorPrimary.Mui-error:after {
    border-bottom: 1px solid #FF0000
}

.MuiFormHelperText-root.Mui-error {
    color:#FF0000 !important;
   }
.userprofileadd { display: flex;width: 100%;min-height: 161px;border-radius: 8px;background: #d9d9d9;
    margin-top: 30px;align-items: center;justify-content: center;}
.addImg {width: 100%;text-align: center;}
.addImg, .addImg img {display: inline-block;}
.userprofileadd_infomations {margin-top: 21px;}
.userprofileadd_infomations b, .userprofileadd_infomations p {display: inline-block;width: 100%;
    font-size: 12px;color: #44454d;}
.userprofileadd_infomations p {font-weight: 400px;margin: 3px 0 35px;padding: 0 20px 0 0;}
.uplode_file {display: inline-block;margin: 18px 0 0;width: 100%;}
.right_info{display: flex !important}
.auditioon_outre_page {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 18px;
    margin-left: 7px;
}


.left_pro_info {
    display: inline-block;
    vertical-align: top;
}

.left_pro_info .projectName {
    display: inline-block;
    width: 100%;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #101820;
    display: flex;
}

.left_pro_info label {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #9B26B6;
    display: flex;
    flex-wrap: wrap;
}

.left_pro_info label .cat_info {
    color: #979797;
    position: relative;
    padding: 0 0 0 24px;
}
.left_pro_info label .cat_one_info {
    color: #979797;
    position: relative;
    padding: 0 0 0 0px;
}
.left_pro_info1 label .cat_info {
    color: #979797;
    position: relative;
    padding: 0 0 0 24px;
}
.left_pro_info1 label .cat_one_info {
    color: #979797;
    position: relative;
    padding: 0 0 0 0px;
}

.left_pro_info1 label .cat_info_label {
    color: #020202;
    position: relative;
    padding: 0 0 0 24px;
}

.left_pro_info label .cat_info_label {
    color: #020202;
    position: relative;
    padding: 0 0 0 24px;
}
.left_pro_info {
    display: inline-block;
    vertical-align: top;
}

.left_us_icon {
    display: inline-block;
    width: 38px;
    vertical-align: top;
    margin: 9px 0 0 0;
    text-align: center;
}

.left_us_icon img {
    display: inline-block;
}

.left_us_info {
    display: inline-block;
    width: 108px;
}
.msg_option p{font-weight: 600;color:rgb(155, 38, 182);float: right;cursor: pointer;margin-top:10px}
.left_pro_info1{
    display: flex;
    justify-content: spa\\;
    justify-content: space-between;
    align-items: center;
}
.left_pro_info1 .MuiButtonBase-root.MuiButton-root{
    width:40%
}
.left_pro_info1 .projectName {
    display: inline-block;
    width: 100%;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #101820;
    display: flex;
}
.saviour{display: flex  ;}


p.dropdown_placeholder_line,
label#disable-close-on-select-label-list {
    font-size: 14px;
    color: #E5E5E5 !important;
    position: absolute;
    font-weight: 400;
    line-height: 2.1;

}

.summary_list_page .custom_dropdown div#custom_select, .MuiInputBase-input, .summary_list_page p.dropdown_placeholder_line {
    font-weight: 400;
    font-size: 14px;
    /* line-height: 21px; */
    color: #44454D !important;
    width:180px
}
/* .custom_dropdown_row .MuiInputBase-root.MuiInput-root.MuiInput-underline{width: 200px;} */
.value.newwidth {
    width: 250px;
}
.type_method button.MuiButtonBase-root.MuiButton-root.MuiButton-contained
 {
    background: #fff !important;
    color: #9B26B6 !important;
}
.type_method button.MuiButtonBase-root.MuiButton-root.MuiButton-contained:hover{
    background: #9B26B6 !important;
    color: #fff !important;
}
.removepadding15{margin-top:25px}
.message_TotalCount span {
    background: #e1bee9;
    border-radius: 100%;
    min-width: 20px;
    height: 17px;
    font-size: 10px;
    padding: 3px;
    margin-top: 3px;
    margin-left: 2px;
    line-height: 14px;
    text-align: center;
    color: #9B26B6;
    display: inline-block;
    font-weight: 600;
}
.show_butMsg {
    background-color: #FFFFFF !important;
    color:#9B26B6 !important;
    font-weight: 600;
    text-transform: capitalize;
    cursor: pointer;
    font-size: 16px;
    float: right;
    padding:6px 30px 6px 30px;
    border: 1px solid #9B26B6;
    border-radius: 5px;
    box-shadow: none;
    margin-top: 20px !important;
    text-transform: inherit;

  }
  .show_butMsg:hover{
    background-color: #9B26B6 !important;
    border: 1px solid #9B26B6;
    color:#fff !important;
    font-weight: 600;
  }

  .message_btn{
    display: flex;
    justify-content: end;
    float: right;
    margin-top: 20px !important;
  }
  .filter_lineBlock{display: flex;flex-direction: column;gap:7px}
  .filter_lineBlock p{    font-weight: 500 !important;
    font-family: "Poppins";
    font-size: 14px;
    margin-left:6px
}
.block_userLists{height: 150px !important;overflow: scroll;}


.filter_lineBlock.filter_line .Conversation {
    align-items: center;
    padding-left: 25px;
}